const brandsFeatures = {
  APC: {
    clothesSelector_hideDeco: true,
  },
  SEZANE: {
    clothesSelector_hideDeco: true,
    order_thirdPersonLabels: false,
    progressBar_hide: true,
    alterations_hideSkipOption: true,
    alterations_hidePrice: true,
    fabrics_skip: true,
    infos_hideCardBlock: true,
    infos_hideOrderSummary: true,
    infos_validateOrderWording: true,
    success_onTheSpotEvent: true,
  },
  ISABEL_MARANT: {
    clothesSelector_hideDeco: true,
  },
  BOURRIENNE: {
    clothesSelector_hideDeco: true,
  },
  CAROLINA_RITZLER: {
    clothesSelector_hideDeco: true,
  },
  MADURA: {
    step1_skipAndAdd1DecoCloth: true,
    step2_showTitleWithoutCloth: true,
    step2_renderClothList: false,
    step2_noLining: true,
    step2_openCurtainCategory: true,
    step3_curtainsOnly: true,
    step4_showCurtainWarning: true,
    step6_showPromoCodeField: false,
  },
  brandsDefault: {
    home_hideBeTilliste: true,
    home_showFullPage: false,
    home_showBrandFooter: true,
    order_thirdPersonLabels: true,
    step1_hideFastVariant: true,
    step1_skipModal: false,
    step5_skip: true,
    infos_hideCardBlockOnlyWhenNewCustomer: false,
    step6_allowShadowAccount: true,
    step6_allowOrderWithoutCard: true,
    step6_skip3ds: true,
  },
  default: {
    // TODO: write description for each feature
    // TODO: do not use numbers for steps but their title
    home_hideBeTilliste: false, // hide the BeTilliste link in menu bar
    home_showFullPage: true,
    home_showBrandFooter: false,
    order_thirdPersonLabels: false, // use "Le prénom de votre client" instead of "Votre prénom"
    progressBar_hide: false,
    step1_skipModal: true, // modal to offer fast order process => go directly to step 3
    step1_skipAndAdd1DecoCloth: false,
    step1_hideFastVariant: false,
    clothesSelector_hideDeco: false,
    alterations_hideSkipOption: false,
    alterations_hidePrice: false,
    step2_showTitleWithoutCloth: false,
    step2_renderClothList: true,
    step2_noLining: false,
    step2_openCurtainCategory: false,
    step3_curtainsOnly: false,
    fabrics_skip: false,
    step4_showCurtainWarning: false,
    step5_skip: false,
    step6_allowShadowAccount: false,
    step6_allowOrderWithoutCard: false,
    step6_showPromoCodeField: true,
    step6_skip3ds: false,
    infos_hideCardBlock: false,
    infos_hideCardBlockOnlyWhenNewCustomer: true,
    infos_hideOrderSummary: false,
    infos_validateOrderWording: false,
    success_onTheSpotEvent: false,
  },
};

// TODO: write test to ensure each feature for a brand has a default value
export default function featureSwitch(featureName) {
  // !brand => tilli website (default)
  const brand = process.env.GATSBY_BRAND || 'default';

  if (typeof brandsFeatures[brand][featureName] === 'boolean') {
    return brandsFeatures[brand][featureName];
  }

  if (typeof brandsFeatures.brandsDefault[featureName] === 'boolean') {
    return brandsFeatures.brandsDefault[featureName];
  }
  return brandsFeatures.default[featureName];
}
