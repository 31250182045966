import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Title2 } from './homeStyledComponents';
import { colors, mobileThresholdPixels } from '../styledComponents';

const Container = styled.div`
  padding: 20px 0px 40px;
  background-color: ${props => props.greyBackground ? colors.lightGrey : colors.white};
  @media (max-width: ${mobileThresholdPixels}) {
    padding: 1px 0px;
  }
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  font-size: 18px;
`;

const BrandFooter = ({ greyBackground }) => (
  <Container id="BrandFooter" greyBackground={greyBackground}>
    <Title2>{'⋅ une question ? ⋅'}</Title2>
    <P>Contactez-nous : contact@tilli.fr | 06.82.33.28.70 </P>
    <P>Pour rappel, les villes couvertes par Tilli sont Paris (et 1ère courronne), Marseille,
      Aix-en-Provence et Metz. Les villes proches de Paris sont : Neuilly, Levallois-Perret, Clichy,
      Issy-Les-Moulineaux, Montreuil, Boulogne-Billancourt, Vincennes, Montrouge, Saint-Mandé,
      Charenton-le-Pont et Ivry-sur-Seine.</P>
    <P>
      Tilli dessert maintenant Noisy-le-Grand, quelques villes dans le Val-de-Marne (Bry-sur-Marne,
      Champigny-sur-Marne, Chennevières-sur-Marne, La
      Queue-en-Brie, Le Plessis-Trévise, Mandres-les-Roses, Marolles-en-Brie, Noiseau,
      Ormesson-sur-Marne, Périgny, Santeny, Villiers-sur-Marne) ainsi qu‘une zone couvrant une
      partie centre-ouest de la Seine-et-Marne.
      À savoir : Annet-sur-Marne, Bailly-Romainvilliers, Brie-Comte-Robert,
      Brou-sur-Chantereine, Bussy-Saint-Georges, Bussy-Saint-Martin, Carnetin, Chalifert,
      Champs-sur-Marne, Chanteloup-en-Brie, Charmentray, Charny, Châtres, Chelles, Chessy,
      Chevry-Cossigny, Claye-Souilly, Collégien, Conches-sur-Gondoire, Coubert, Coupvray,
      Courtry, Crèvecœur-en-Brie, Croissy-Beaubourg, Dammartin-sur-Tigeaux, Dampmart,
      Émerainville, Évry-Grégy-sur-Yerre, Favières, Férolles-Attilly, Ferrières-en-Brie,
      Fontenay-Trésigny, Fresnes-sur-Marne, Gouvernes, Gressy, Gretz-Armainvilliers,
      Grisy-Suisnes, Guermantes, Jossigny, La Houssaye-en-Brie, Lagny-sur-Marne, Le Pin, Les
      Chapelles-Bourbon, Lésigny, Liverdy-en-Brie, Lognes, Magny-le-Hongre, Marles-en-Brie,
      Messy, Montévrain, Mortcerf, Neufmoutiers-en-Brie, Noisiel, Ozoir-la-Ferrière, Pomponne,
      Pontault-Combault, Pontcarré, Précy-sur-Marne, Presles-en-Brie, Roissy-en-Brie,
      Saint-Mesmes, Saint-Thibault-des-Vignes, Serris, Servon, Soignolles-en-Brie, Solers,
      Thorigny-sur-Marne, Tigeaux, Torcy, Tournan-en-Brie, Vaires-sur-Marne,
      Villeneuve-le-Comte, Villeneuve-Saint-Denis, Villeroy et Villevaudé.
    </P>
  </Container>
);

BrandFooter.propTypes = {
  greyBackground: PropTypes.bool,
};

BrandFooter.defaultProps = {
  greyBackground: true,
};

export default BrandFooter;
