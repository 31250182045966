import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Breadcrumb from './Breadcrumb';
import { toFormattedText } from '../../services/formatting';
import { colors, fontSizes, margins, ContainerBlock } from '../../components/Home/v2/StyledComponents';

const StyledContainerBlock = styled(ContainerBlock) `
  margin-bottom: ${margins.m};
`;

const TextContainer = styled.p`
  font-family: Roboto;
  font-size: ${fontSizes.s};
  text-align: center;
  color: ${colors.navy};
  margin: 0px;
`;

const FootNote = ({ notes, itemListElement }) => (
  <StyledContainerBlock>
    <TextContainer>{toFormattedText(notes)}</TextContainer>
    {itemListElement && <Breadcrumb itemListElement={itemListElement} />}
  </StyledContainerBlock>
);

FootNote.propTypes = {
  notes: PropTypes.string.isRequired,
  itemListElement: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    item: PropTypes.string.isRequired,
  }).isRequired),
};

FootNote.defaultProps = {
  itemListElement: null,
};

export default FootNote;
