import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { toFormattedText } from '../../services/formatting';
import routesMap from '../../Routes';
import Dots from '../../components/Home/v2/Dots';
import { trackEvent } from '../../services/analytics';
import {
  colors, fontSizes, fontWeight, margins, mobileThresholdPixels,
  Title2, Title3, Subtitle2, ButtonContainer, Button, ContainerBlock, Column,
} from '../../components/Home/v2/StyledComponents';

const StyledTitle3 = styled(Title3)`
  font-size: ${fontSizes.m};
  font-weight: bold;
  width: 70%;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: left;
    width: 95%;
  }
`;

const SubContainer = styled.div`
  color: ${colors.navy};
  display: flex;
  align-items: stretch;
  align-content: center;
  margin: 0px;
  ${props => props.first && `margin-top: ${margins.l}`};
  ${props => props.last && `margin-bottom: ${margins.l}`};
  flex-direction: ${props => props.imgFirst ? 'row' : 'row-reverse'};

  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    margin: ${margins.s} 0px;
  }
`;

const ImgContainer = styled.div`
  flex: 1;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const Img = styled.img`
  object-fit: cover;
  position: relative;
  width: 100%;
  max-height: 400px;
`;

const StyledButtonContainer = styled(ButtonContainer)`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.s};
  }
`;

const TextMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  padding: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    margin: ${margins.m} 0px;
    ${props => props.last && 'margin-bottom: 0px'};
  }
`;

const TextContainer = styled.div`
  font-family: Roboto;
  font-size: 15px;
  font-weight: ${fontWeight.light};
  color: ${colors.navy};
  line-height: 22px;
  width: 70%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 95%;
    font-size: ${fontSizes.s};
  }
`;

const HowItWorks = ({ subTitle, blocks }) => (
  <ContainerBlock id="HowItWorks">
    <Column justifyContent={'center'} alignItems={'center'}>
      <Title2>Comment ça marche</Title2>
      <Dots />
      <Subtitle2>{toFormattedText(subTitle)}</Subtitle2>
    </Column>
    {blocks.map((block, index) =>
      <SubContainer
        imgFirst={(index % 2) === 0} key={block.title}
        first={index === 0} last={index === blocks.length - 1}
      >
        <ImgContainer>
          <Img src={block.image.publicURL} alt={block.imageAlt} />
        </ImgContainer>
        <TextMainContainer imgFirst={(block.index % 2) === 0} last={index === blocks.length - 1}>
          <StyledTitle3>{block.title}</StyledTitle3>
          <TextContainer>{block.paragraph}</TextContainer>
          {(index === blocks.length - 1) &&
            <StyledButtonContainer>
              <Link
                to={routesMap.OrderPage.url}
                onClick={() => trackEvent('click', 'prendreRDV_block-comment-ca-marche')}
              >
                <Button>Prendre RDV</Button>
              </Link>
            </StyledButtonContainer>
          }
        </TextMainContainer>
      </SubContainer>,
    )}
  </ContainerBlock>
);

HowItWorks.propTypes = {
  subTitle: PropTypes.string.isRequired,
  blocks: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    image: PropTypes.shape({
      publicURL: PropTypes.string.isRequired,
    }).isRequired,
    imageAlt: PropTypes.string.isRequired,
  })).isRequired,
};


export default HowItWorks;
