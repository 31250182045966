import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { graphql } from 'gatsby';

import featureSwitch from '../services/featureSwitch';
import { RelativePosition, Container, Content } from '../components/Home/v2/StyledComponents';
import Layout from '../layouts/index';
import MenuBar from '../components/Home/MenuBar';
import HeaderPhoto from '../components/LandingPage/HeaderPhoto';
import HowItWorks from '../components/LandingPage/HowItWorks';
import TheyLoveTilli from '../components/LandingPage/TheyLoveTilli';
import OurServices from '../components/LandingPage/OurServices';
import MoreAbout from '../components/LandingPage/MoreAbout';
import FootNote from '../components/LandingPage/FootNote';
import PartnersPager from '../components/LandingPage/PartnersPager';
import Footer from '../components/Home/Footer';
import BrandFooter from '../components/Home/BrandFooter';
import ReferrerBanner from '../components/Home/ReferrerBanner';

class LandingPageTemplate extends React.Component {
  componentDidMount() {
    if (typeof window === 'undefined') return;
    window.scrollTo(0, 0);
    window.onbeforeunload = null;
  }

  render() {
    const { data, referrer, landingVersion } = this.props;
    return (
      <Layout routeSlug={data.landingsJson.slug}>
        <Container>
          <Content full>
            {referrer && <ReferrerBanner referrer={referrer} />}
            <MenuBar landingVersion={!!landingVersion} />
            <RelativePosition>
              <HeaderPhoto
                landingVersion={landingVersion}
                items={data.landingsJson.header.items}
              />
            </RelativePosition>
            <Content full>
              <HowItWorks
                subTitle={data.landingsJson.howItWorks.subTitle} blocks={data.landingsJson.howItWorks.blocks}
              />
            </Content>
            <Content>
              <TheyLoveTilli feedbacks={data.landingsJson.theyLoveTilli} />
              <OurServices
                subTitle={data.landingsJson.ourServices.subTitle} imageList={data.landingsJson.ourServices.imageList}
              />
            </Content>
            {featureSwitch('home_showFullPage') &&
              <div>
                <Content>
                  <LazyLoad height={200} offset={1000} once>
                    <PartnersPager landingVersion={!!landingVersion} />
                  </LazyLoad>
                  {data.landingsJson.moreAbout &&
                    <MoreAbout
                      subTitle={data.landingsJson.moreAbout.subTitle}
                      paragraph={data.landingsJson.moreAbout.paragraph}
                      link={data.landingsJson.moreAbout.link}
                    />
                  }
                  <FootNote notes={data.landingsJson.footNote} itemListElement={data.landingsJson.breadcrumb} />
                </Content>
                <Footer />
              </div>
            }
            {featureSwitch('home_showBrandFooter') &&
              <BrandFooter />
            }
          </Content>
        </Container>
      </Layout>
    );
  }
}

LandingPageTemplate.propTypes = {
  data: PropTypes.shape({
    landingsJson: PropTypes.shape({}).isRequired,
  }).isRequired,
  referrer: PropTypes.shape({}),
  landingVersion: PropTypes.string,
};

LandingPageTemplate.defaultProps = {
  referrer: null,
  landingVersion: null,
};

export default LandingPageTemplate;

export const landingPageQuery = graphql`
query landingsQuery($path: String!) {
  landingsJson(path: { eq: $path }) {
    slug
    path
    header {
      items {
        title
        subTitle
        image {
          publicURL
        }
        imageLow {
          publicURL
        }
        imageMobile {
          publicURL
        }
        imageLowMobile {
          publicURL
        }
        imageAlt
        isNavy
      }
    }
    howItWorks {
      subTitle
      blocks {
        title
        paragraph
        image {
          publicURL
        }
        imageAlt
      }
    }
    theyLoveTilli {
      index
      author
      text
    }
    ourServices {
      subTitle
      imageList {
        image {
          publicURL
        }
        imageAlt
        caution
        link
        trackingId
      }
    }
    moreAbout {
      subTitle
      paragraph
      link
    }
    footNote
    breadcrumb {
      position
      name
      item
    }
  }
}`;
