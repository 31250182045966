import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { ViewPager, Frame, Track, View } from 'react-view-pager';

import routesMap from '../../Routes';
import { trackEvent } from '../../services/analytics';
import { toFormattedText } from '../../services/formatting';
import isMobile from '../../services/isMobile';
import Dots from '../../components/Home/v2/Dots';
import {
  colors, margins, fontSizes, mobileThresholdPixels,
  Title2, Subtitle2, Button, ButtonContainer, ContainerBlock, Row, Column, LineV,
} from '../../components/Home/v2/StyledComponents';

import arrowLeft from '../../assets/arrow.left.navy.svg';
import arrowRight from '../../assets/arrow.right.navy.svg';
import arrowEmpty from '../../assets/headers/arrow.empty.png';

const viewsToShow = isMobile() ? 1 : 3;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${margins.m} 0px ${margins.xl} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
  }
`;

const ControlContainer = styled.div`
  z-index: 1;
  width: 2%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 6%;
  }
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 25px;

  // fix image flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);

  @media (max-width: ${mobileThresholdPixels}) {
    width: 14px;
  }
`;

const StyledButton = styled(Button)`
  padding: 18px 20px 14px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 18px 10px 14px;
    margin: ${margins.m} 0px;
  }
`;

const FeedbackContainer = styled.div`
  margin: 0px ${margins.m};
`;

const frameStyle = {
  width: '100%',
};

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
};

const AuthorNameSpan = styled.span`
  display: flex;
  align-items: center;
  font-size: ${fontSizes.s};
  font-weight: bold;
  font-family: Roboto;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const TextContainer = styled.span`
  font-weight: 400;
  font-size: ${fontSizes.s};
  font-family: 'Roboto';
`;

const feedbackDotSize = 9;
const FeedbackDot = styled.span`
  margin-top: 2px;
  background-color: ${colors.navy};
  width: ${feedbackDotSize}px;
  height: ${feedbackDotSize}px;
  border-radius: 50%;
  margin-left: ${margins.xs};
`;

class TheyLoveTilli extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: 0,
      nextView: 1,
      noLeftArrow: true,
      noRightArrow: false,
    };
  }

  next() {
    if (!this.state.noRightArrow) this.track.next();
  }

  prev() {
    if (!this.state.noLeftArrow) this.track.prev();
  }

  render() {
    const feedbacks = this.props.feedbacks;
    return (
      <ContainerBlock withMargin>
        <Column justifyContent={'center'} alignItems={'center'}>
          <Title2>Témoignages</Title2>
          <Dots />
          <Subtitle2>Ils ont essayé,<br />ils ont aimé.</Subtitle2>
        </Column>
        <SubContainer>
          <ViewPager tag="main" style={viewPagerStyle}>
            <ControlContainer right>
              <ControlImg
                alt="Flèche précédente pour tous types de retouches"
                src={this.state.noLeftArrow ? arrowEmpty : arrowLeft}
                onClick={() => this.prev()}
              />
            </ControlContainer>
            <Frame className="frame" style={frameStyle}>
              <Track
                ref={(c) => { this.track = c; }}
                viewsToShow={viewsToShow}
                onViewChange={currentIndicies =>
                  this.setState({
                    currentView: (this.track.context.pager.currentView.index),
                    nextView: (this.track.context.pager.currentView.index + 1) % feedbacks.length,
                    noLeftArrow: currentIndicies[0] === 0,
                    noRightArrow: currentIndicies[0] === feedbacks.length - viewsToShow,
                  })}
                className="track"
              >
                {feedbacks.map(feedback =>
                  <View className="view" key={feedback.index}>
                    <Row justifyContent={'space-between'} alignContent={'center'} alignItems={'flex-start'}>
                      <FeedbackContainer>
                        <AuthorNameSpan>
                          {feedback.author}{' '}
                          <FeedbackDot />
                          <FeedbackDot />
                          <FeedbackDot />
                          <FeedbackDot />
                          <FeedbackDot />
                        </AuthorNameSpan>
                        <TextContainer>{toFormattedText(feedback.text)}</TextContainer>
                      </FeedbackContainer>
                      { !isMobile() &&
                        (this.state.currentView === feedback.index || this.state.nextView === feedback.index)
                          &&
                          <LineV height={'100px'} />
                      }
                    </Row>
                  </View>,
                )}
              </Track>
            </Frame>
            <ControlContainer right>
              <ControlImg
                alt="Flèche suivante de boutique retoucherie"
                src={this.state.noRightArrow ? arrowEmpty : arrowRight}
                onClick={() => this.next()}
              />
            </ControlContainer>
          </ViewPager>
          <Link
            to={routesMap.OrderPage.url}
            onClick={() => trackEvent('click', 'faites-comme-eux_block-ils-ont-aime')}
          >
            <ButtonContainer margin={!isMobile() ? undefined : `${margins.l} 0px`}>
              <StyledButton>Faites comme eux et essayez Tilli !</StyledButton>
            </ButtonContainer>
          </Link>
        </SubContainer>
      </ContainerBlock>
    );
  }
}

TheyLoveTilli.propTypes = {
  feedbacks: PropTypes.arrayOf(PropTypes.shape({
    index: PropTypes.number.isRequired,
    author: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

export default TheyLoveTilli;
